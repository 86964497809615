import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/_auth/_app/accessions_/$id/")({
  parseParams: (params) => {
    return { id: z.coerce.number().parse(params.id) };
  },
  // loader: async ({ params: { id } }) => {
  //   await Promise.all([
  //     apiUtils.accession.get.ensureData({ id }),
  //     apiUtils.accession.testOrders.ensureData(id),
  //     apiUtils.accession.holds.ensureData(id),
  //     apiUtils.accession.samples.ensureData(id),
  //     apiUtils.accession.reports.ensureData(id),
  //     apiUtils.accession.comments.list.ensureData({ accessionId: id }),
  //   ]);
  // },
});
