import React from "react";
import { twMerge } from "tailwind-merge";

const AccordionContext = React.createContext<{
  name?: string;
} | null>(null);

const useAccordionContext = () => {
  const context = React.useContext(AccordionContext);

  if (!context) {
    return {};
  }
  return context;
};

function Accordion({
  name,
  className,
  ...props
}: React.JSX.IntrinsicElements["section"] & { name?: string }) {
  return (
    <AccordionContext.Provider value={{ name }}>
      <section
        {...props}
        className={twMerge("flex w-full flex-col", className)}
      />
    </AccordionContext.Provider>
  );
}

function Disclosure({
  className,
  ...props
}: React.JSX.IntrinsicElements["details"]) {
  const { name } = useAccordionContext();

  return (
    <details
      {...props}
      className={twMerge("group", className)}
      {...(name && { name })}
    />
  );
}

function DisclosureControl({
  className,
  ...props
}: React.JSX.IntrinsicElements["summary"]) {
  return (
    <summary
      {...props}
      className={twMerge(
        "flex w-full cursor-pointer select-none justify-between text-left font-medium outline-none [&::-webkit-details-marker]:hidden",
        className,
      )}
    />
  );
}

Disclosure.Control = DisclosureControl;

export { Disclosure };
