import {
  getLocalTimeZone,
  now,
  toCalendarDate,
  toCalendarDateTime,
} from "@internationalized/date";
import { CalendarDaysIcon } from "lucide-react";
import type React from "react";
import { useCallback, useContext } from "react";
import {
  DatePicker as DatePickerPrimitive,
  type DatePickerProps as DatePickerPrimitiveProps,
  DatePickerStateContext,
  type DateValue,
  type DialogProps,
  type PopoverProps,
  type ValidationResult,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { Button } from "./button";
import { Calendar } from "./calendar";
import { DateInput } from "./date-field";
import { Description, FieldError, FieldGroup, Label } from "./field";
import { Popover } from "./popover";
import { ctr } from "./primitive";

const datePickerStyles = tv({
  slots: {
    base: "group flex flex-col",
    datePickerIcon:
      "group mr-1 h-6 dark:[&>[data-slot=icon]]:text-zinc-400 [&>[data-slot=icon]]:text-zinc-800 w-7 rounded outline-offset-0 hover:bg-transparent pressed:bg-transparent",
    calendarIcon: "size-4 text-muted-foreground group-open:text-foreground",
    datePickerInput: "w-full px-2 uppercase text-xs",
    dateRangePickerInputStart: "px-2 lg:text-sm uppercase text-base",
    dateRangePickerInputEnd:
      "flex-1 px-2 py-1.5 lg:text-sm uppercase text-base",
    dateRangePickerDash:
      "text-zinc-800 group-disabled:text-zinc-200 forced-colors:text-[ButtonText] group-disabled:forced-colors:text-[GrayText]",
  },
});

const { base, datePickerIcon, calendarIcon, datePickerInput } =
  datePickerStyles();

interface DatePickerOverlayProps
  extends Omit<DialogProps, "children" | "className" | "style">,
    Omit<PopoverProps, "children" | "className" | "style"> {
  className?: string | ((values: { defaultClassName?: string }) => string);
  children?: React.ReactNode;
  closeButton?: boolean;
}

const DatePickerOverlay = ({
  closeButton = true,
  ...props
}: DatePickerOverlayProps) => {
  return (
    <Popover.Content
      showArrow={false}
      className="flex justify-center p-4 sm:p-2 sm:pt-3 sm:max-w-[17.2rem] sm:min-w-[17rem]"
      {...props}
    >
      <Calendar />
      {closeButton && (
        <div className="sm:hidden py-2.5 flex justify-center mx-auto w-full max-w-[inherit]">
          <Popover.Close shape="circle" className="w-full">
            Close
          </Popover.Close>
        </div>
      )}
    </Popover.Content>
  );
};

const DatePickerIcon = () => (
  <Button size="square-medium" appearance="plain" className={datePickerIcon()}>
    <CalendarDaysIcon
      data-slot="icon"
      strokeWidth={1.5}
      aria-hidden
      className={calendarIcon()}
    />
  </Button>
);

interface DatePickerProps<T extends DateValue>
  extends DatePickerPrimitiveProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
}

const DatePicker = <T extends DateValue>({
  label,
  className,
  description,
  errorMessage,
  ...props
}: DatePickerProps<T>) => {
  return (
    <DatePickerPrimitive
      {...props}
      className={ctr(className, base())}
      hourCycle={24}
    >
      {label && <Label className="mb-1.5">{label}</Label>}
      <DatePickerInner />
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
      <DatePickerOverlay />
    </DatePickerPrimitive>
  );
};

function DatePickerInner() {
  const { onKeyDown } = useDatePickerShortcuts();

  return (
    <FieldGroup className="min-w-40" onKeyDown={onKeyDown}>
      <DateInput className={datePickerInput()} />
      <DatePickerIcon />
    </FieldGroup>
  );
}

function useDatePickerShortcuts() {
  const ctx = useContext(DatePickerStateContext);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const key = e.key.toLowerCase();
      if (key === "t" || key === "y") {
        e.stopPropagation();
        let nowValue = now(getLocalTimeZone());
        if (key === "y") {
          nowValue = nowValue.subtract({ days: 1 });
        }
        ctx?.setValue(
          ctx.hasTime ? toCalendarDateTime(nowValue) : toCalendarDate(nowValue),
        );
      }
    },
    [ctx?.hasTime, ctx?.setValue],
  );

  return { onKeyDown };
}

export {
  DatePicker,
  DatePickerIcon,
  DatePickerOverlay,
  type DatePickerProps,
  type DateValue,
  type ValidationResult,
};
