/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as HealthImport } from './routes/health'
import { Route as ErrorImport } from './routes/error'
import { Route as AuthImport } from './routes/_auth'
import { Route as AuthAdminRouteImport } from './routes/_auth.admin/route'
import { Route as AuthAdminIndexImport } from './routes/_auth.admin/index'
import { Route as AuthAppIndexImport } from './routes/_auth._app/index'
import { Route as AuthAppRunsRouteImport } from './routes/_auth._app/runs/route'
import { Route as AuthAppReportsRouteImport } from './routes/_auth._app/reports/route'
import { Route as AuthAppAccessionsRouteImport } from './routes/_auth._app/accessions/route'
import { Route as AuthAppHelpNotificationsImport } from './routes/_auth._app/help/notifications'
import { Route as AuthAppBoardIdImport } from './routes/_auth._app/board/$id'
import { Route as AuthAppAccessionsNewv2Import } from './routes/_auth._app/accessions/newv2'
import { Route as AuthAppAccessionsNewImport } from './routes/_auth._app/accessions/new'
import { Route as AuthAppSubjectsIdRouteImport } from './routes/_auth._app/subjects.$id/route'
import { Route as AuthAppRunsIdIndexImport } from './routes/_auth._app/runs/$id.index'
import { Route as AuthAppAccessionsIdIndexImport } from './routes/_auth._app/accessions_.$id/index'
import { Route as AuthAdminWorkflowsIdEditImport } from './routes/_auth.admin/workflows/$id.edit'
import { Route as AuthAdminUsersIdEditImport } from './routes/_auth.admin/users/$id.edit'
import { Route as AuthAdminTubesIdEditImport } from './routes/_auth.admin/tubes/$id.edit'
import { Route as AuthAdminResultsIdEditImport } from './routes/_auth.admin/results/$id.edit'
import { Route as AuthAdminPodsIdEditImport } from './routes/_auth.admin/pods/$id.edit'
import { Route as AuthAdminPanelsIdEditImport } from './routes/_auth.admin/panels/$id.edit'
import { Route as AuthAdminLabsIdEditImport } from './routes/_auth.admin/labs/$id.edit'
import { Route as AuthAdminInstrumentsIdEditImport } from './routes/_auth.admin/instruments/$id.edit'
import { Route as AuthAdminClientsIdEditImport } from './routes/_auth.admin/clients/$id.edit'
import { Route as AuthAppSubjectsIdReportImport } from './routes/_auth._app/subjects.$id/report'
import { Route as AuthAppSubjectsIdCreateImport } from './routes/_auth._app/subjects.$id/create'
import { Route as AuthAppSubjectsIdAccessionImport } from './routes/_auth._app/subjects.$id/accession'
import { Route as AuthAppAccessionsIdReportImport } from './routes/_auth._app/accessions_.$id/report'
import { Route as AuthAppAccessionsIdModalImport } from './routes/_auth._app/accessions/$id.modal'
import { Route as AuthAdminTestsIdEditIndexImport } from './routes/_auth.admin/tests/$id.edit.index'
import { Route as AuthAdminTubesFieldsIdEditImport } from './routes/_auth.admin/tubes/fields.$id.edit'
import { Route as AuthAdminTubesFieldSetsIdEditImport } from './routes/_auth.admin/tubes/field-sets.$id.edit'
import { Route as AuthAdminAuthProvidersIdEditImport } from './routes/_auth.admin/auth/providers.$id.edit'
import { Route as AuthAppRunsIdWorklistTestIdImport } from './routes/_auth._app/runs/$id.worklist.$testId'
import { Route as AuthAdminTestsIdEditResultsSpecIdImport } from './routes/_auth.admin/tests/$id.edit.results.$specId'
import { Route as AuthAppRunsIdWorklistTestIdResultsOrderIdImport } from './routes/_auth._app/runs/$id.worklist.$testId.results.$orderId'

// Create Virtual Routes

const AuthAppRouteLazyImport = createFileRoute('/_auth/_app')()
const AuthAppBoardRouteLazyImport = createFileRoute('/_auth/_app/board')()
const AuthAdminWorkflowsIndexLazyImport = createFileRoute(
  '/_auth/admin/workflows/',
)()
const AuthAdminUsersIndexLazyImport = createFileRoute('/_auth/admin/users/')()
const AuthAdminTubesIndexLazyImport = createFileRoute('/_auth/admin/tubes/')()
const AuthAdminTestsIndexLazyImport = createFileRoute('/_auth/admin/tests/')()
const AuthAdminSettingsIndexLazyImport = createFileRoute(
  '/_auth/admin/settings/',
)()
const AuthAdminResultsIndexLazyImport = createFileRoute(
  '/_auth/admin/results/',
)()
const AuthAdminPodsIndexLazyImport = createFileRoute('/_auth/admin/pods/')()
const AuthAdminPanelsIndexLazyImport = createFileRoute('/_auth/admin/panels/')()
const AuthAdminLabsIndexLazyImport = createFileRoute('/_auth/admin/labs/')()
const AuthAdminInstrumentsIndexLazyImport = createFileRoute(
  '/_auth/admin/instruments/',
)()
const AuthAdminClientsIndexLazyImport = createFileRoute(
  '/_auth/admin/clients/',
)()
const AuthAdminAuthIndexLazyImport = createFileRoute('/_auth/admin/auth/')()
const AuthAdminAuditIndexLazyImport = createFileRoute('/_auth/admin/audit/')()
const AuthAppSearchIndexLazyImport = createFileRoute('/_auth/_app/search/')()
const AuthAppRunsIndexLazyImport = createFileRoute('/_auth/_app/runs/')()
const AuthAppReportsIndexLazyImport = createFileRoute('/_auth/_app/reports/')()
const AuthAppHelpIndexLazyImport = createFileRoute('/_auth/_app/help/')()
const AuthAppAccessionsIndexLazyImport = createFileRoute(
  '/_auth/_app/accessions/',
)()
const AuthAdminWorkflowsNewLazyImport = createFileRoute(
  '/_auth/admin/workflows/new',
)()
const AuthAdminTubesNewLazyImport = createFileRoute('/_auth/admin/tubes/new')()
const AuthAdminTestsNewLazyImport = createFileRoute('/_auth/admin/tests/new')()
const AuthAdminResultsNewLazyImport = createFileRoute(
  '/_auth/admin/results/new',
)()
const AuthAdminPodsNewLazyImport = createFileRoute('/_auth/admin/pods/new')()
const AuthAdminPanelsNewLazyImport = createFileRoute(
  '/_auth/admin/panels/new',
)()
const AuthAdminLabsNewLazyImport = createFileRoute('/_auth/admin/labs/new')()
const AuthAdminInstrumentsNewLazyImport = createFileRoute(
  '/_auth/admin/instruments/new',
)()
const AuthAdminClientsNewLazyImport = createFileRoute(
  '/_auth/admin/clients/new',
)()
const AuthAppAuthProfileLazyImport = createFileRoute(
  '/_auth/_app/auth/profile',
)()
const AuthAdminTubesFieldsNewLazyImport = createFileRoute(
  '/_auth/admin/tubes/fields/new',
)()
const AuthAdminTubesFieldSetsNewLazyImport = createFileRoute(
  '/_auth/admin/tubes/field-sets/new',
)()
const AuthAdminAuthProvidersNewLazyImport = createFileRoute(
  '/_auth/admin/auth/providers/new',
)()

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const HealthRoute = HealthImport.update({
  id: '/health',
  path: '/health',
  getParentRoute: () => rootRoute,
} as any)

const ErrorRoute = ErrorImport.update({
  id: '/error',
  path: '/error',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthAppRouteLazyRoute = AuthAppRouteLazyImport.update({
  id: '/_app',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/route.lazy').then((d) => d.Route),
)

const AuthAdminRouteRoute = AuthAdminRouteImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/route.lazy').then((d) => d.Route),
)

const AuthAdminIndexRoute = AuthAdminIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any)

const AuthAppIndexRoute = AuthAppIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/index.lazy').then((d) => d.Route),
)

const AuthAppBoardRouteLazyRoute = AuthAppBoardRouteLazyImport.update({
  id: '/board',
  path: '/board',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/board/route.lazy').then((d) => d.Route),
)

const AuthAppRunsRouteRoute = AuthAppRunsRouteImport.update({
  id: '/runs',
  path: '/runs',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any)

const AuthAppReportsRouteRoute = AuthAppReportsRouteImport.update({
  id: '/reports',
  path: '/reports',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any)

const AuthAppAccessionsRouteRoute = AuthAppAccessionsRouteImport.update({
  id: '/accessions',
  path: '/accessions',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any)

const AuthAdminWorkflowsIndexLazyRoute =
  AuthAdminWorkflowsIndexLazyImport.update({
    id: '/workflows/',
    path: '/workflows/',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any).lazy(() =>
    import('./routes/_auth.admin/workflows/index.lazy').then((d) => d.Route),
  )

const AuthAdminUsersIndexLazyRoute = AuthAdminUsersIndexLazyImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/users/index.lazy').then((d) => d.Route),
)

const AuthAdminTubesIndexLazyRoute = AuthAdminTubesIndexLazyImport.update({
  id: '/tubes/',
  path: '/tubes/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/tubes/index.lazy').then((d) => d.Route),
)

const AuthAdminTestsIndexLazyRoute = AuthAdminTestsIndexLazyImport.update({
  id: '/tests/',
  path: '/tests/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/tests/index.lazy').then((d) => d.Route),
)

const AuthAdminSettingsIndexLazyRoute = AuthAdminSettingsIndexLazyImport.update(
  {
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any,
).lazy(() =>
  import('./routes/_auth.admin/settings/index.lazy').then((d) => d.Route),
)

const AuthAdminResultsIndexLazyRoute = AuthAdminResultsIndexLazyImport.update({
  id: '/results/',
  path: '/results/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/results/index.lazy').then((d) => d.Route),
)

const AuthAdminPodsIndexLazyRoute = AuthAdminPodsIndexLazyImport.update({
  id: '/pods/',
  path: '/pods/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/pods/index.lazy').then((d) => d.Route),
)

const AuthAdminPanelsIndexLazyRoute = AuthAdminPanelsIndexLazyImport.update({
  id: '/panels/',
  path: '/panels/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/panels/index.lazy').then((d) => d.Route),
)

const AuthAdminLabsIndexLazyRoute = AuthAdminLabsIndexLazyImport.update({
  id: '/labs/',
  path: '/labs/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/labs/index.lazy').then((d) => d.Route),
)

const AuthAdminInstrumentsIndexLazyRoute =
  AuthAdminInstrumentsIndexLazyImport.update({
    id: '/instruments/',
    path: '/instruments/',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any).lazy(() =>
    import('./routes/_auth.admin/instruments/index.lazy').then((d) => d.Route),
  )

const AuthAdminClientsIndexLazyRoute = AuthAdminClientsIndexLazyImport.update({
  id: '/clients/',
  path: '/clients/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/clients/index.lazy').then((d) => d.Route),
)

const AuthAdminAuthIndexLazyRoute = AuthAdminAuthIndexLazyImport.update({
  id: '/auth/',
  path: '/auth/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/auth/index.lazy').then((d) => d.Route),
)

const AuthAdminAuditIndexLazyRoute = AuthAdminAuditIndexLazyImport.update({
  id: '/audit/',
  path: '/audit/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/audit/index.lazy').then((d) => d.Route),
)

const AuthAppSearchIndexLazyRoute = AuthAppSearchIndexLazyImport.update({
  id: '/search/',
  path: '/search/',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/search/index.lazy').then((d) => d.Route),
)

const AuthAppRunsIndexLazyRoute = AuthAppRunsIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthAppRunsRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/runs/index.lazy').then((d) => d.Route),
)

const AuthAppReportsIndexLazyRoute = AuthAppReportsIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthAppReportsRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/reports/index.lazy').then((d) => d.Route),
)

const AuthAppHelpIndexLazyRoute = AuthAppHelpIndexLazyImport.update({
  id: '/help/',
  path: '/help/',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/help/index.lazy').then((d) => d.Route),
)

const AuthAppAccessionsIndexLazyRoute = AuthAppAccessionsIndexLazyImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => AuthAppAccessionsRouteRoute,
  } as any,
).lazy(() =>
  import('./routes/_auth._app/accessions/index.lazy').then((d) => d.Route),
)

const AuthAdminWorkflowsNewLazyRoute = AuthAdminWorkflowsNewLazyImport.update({
  id: '/workflows/new',
  path: '/workflows/new',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/workflows/new.lazy').then((d) => d.Route),
)

const AuthAdminTubesNewLazyRoute = AuthAdminTubesNewLazyImport.update({
  id: '/tubes/new',
  path: '/tubes/new',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/tubes/new.lazy').then((d) => d.Route),
)

const AuthAdminTestsNewLazyRoute = AuthAdminTestsNewLazyImport.update({
  id: '/tests/new',
  path: '/tests/new',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/tests/new.lazy').then((d) => d.Route),
)

const AuthAdminResultsNewLazyRoute = AuthAdminResultsNewLazyImport.update({
  id: '/results/new',
  path: '/results/new',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/results/new.lazy').then((d) => d.Route),
)

const AuthAdminPodsNewLazyRoute = AuthAdminPodsNewLazyImport.update({
  id: '/pods/new',
  path: '/pods/new',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/pods/new.lazy').then((d) => d.Route),
)

const AuthAdminPanelsNewLazyRoute = AuthAdminPanelsNewLazyImport.update({
  id: '/panels/new',
  path: '/panels/new',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/panels/new.lazy').then((d) => d.Route),
)

const AuthAdminLabsNewLazyRoute = AuthAdminLabsNewLazyImport.update({
  id: '/labs/new',
  path: '/labs/new',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/labs/new.lazy').then((d) => d.Route),
)

const AuthAdminInstrumentsNewLazyRoute =
  AuthAdminInstrumentsNewLazyImport.update({
    id: '/instruments/new',
    path: '/instruments/new',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any).lazy(() =>
    import('./routes/_auth.admin/instruments/new.lazy').then((d) => d.Route),
  )

const AuthAdminClientsNewLazyRoute = AuthAdminClientsNewLazyImport.update({
  id: '/clients/new',
  path: '/clients/new',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/clients/new.lazy').then((d) => d.Route),
)

const AuthAppAuthProfileLazyRoute = AuthAppAuthProfileLazyImport.update({
  id: '/auth/profile',
  path: '/auth/profile',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/auth/profile.lazy').then((d) => d.Route),
)

const AuthAppHelpNotificationsRoute = AuthAppHelpNotificationsImport.update({
  id: '/help/notifications',
  path: '/help/notifications',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/help/notifications.lazy').then((d) => d.Route),
)

const AuthAppBoardIdRoute = AuthAppBoardIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => AuthAppBoardRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/board/$id.lazy').then((d) => d.Route),
)

const AuthAppAccessionsNewv2Route = AuthAppAccessionsNewv2Import.update({
  id: '/newv2',
  path: '/newv2',
  getParentRoute: () => AuthAppAccessionsRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/accessions/newv2.lazy').then((d) => d.Route),
)

const AuthAppAccessionsNewRoute = AuthAppAccessionsNewImport.update({
  id: '/new',
  path: '/new',
  getParentRoute: () => AuthAppAccessionsRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/accessions/new.lazy').then((d) => d.Route),
)

const AuthAppSubjectsIdRouteRoute = AuthAppSubjectsIdRouteImport.update({
  id: '/subjects/$id',
  path: '/subjects/$id',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/subjects.$id/route.lazy').then((d) => d.Route),
)

const AuthAppRunsIdIndexRoute = AuthAppRunsIdIndexImport.update({
  id: '/$id/',
  path: '/$id/',
  getParentRoute: () => AuthAppRunsRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/runs/$id.index.lazy').then((d) => d.Route),
)

const AuthAppAccessionsIdIndexRoute = AuthAppAccessionsIdIndexImport.update({
  id: '/accessions_/$id/',
  path: '/accessions/$id/',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/accessions_.$id/index.lazy').then((d) => d.Route),
)

const AuthAdminTubesFieldsNewLazyRoute =
  AuthAdminTubesFieldsNewLazyImport.update({
    id: '/tubes/fields/new',
    path: '/tubes/fields/new',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any).lazy(() =>
    import('./routes/_auth.admin/tubes/fields.new.lazy').then((d) => d.Route),
  )

const AuthAdminTubesFieldSetsNewLazyRoute =
  AuthAdminTubesFieldSetsNewLazyImport.update({
    id: '/tubes/field-sets/new',
    path: '/tubes/field-sets/new',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any).lazy(() =>
    import('./routes/_auth.admin/tubes/field-sets.new.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthAdminAuthProvidersNewLazyRoute =
  AuthAdminAuthProvidersNewLazyImport.update({
    id: '/auth/providers/new',
    path: '/auth/providers/new',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any).lazy(() =>
    import('./routes/_auth.admin/auth/providers.new.lazy').then((d) => d.Route),
  )

const AuthAdminWorkflowsIdEditRoute = AuthAdminWorkflowsIdEditImport.update({
  id: '/workflows/$id/edit',
  path: '/workflows/$id/edit',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/workflows/$id.edit.lazy').then((d) => d.Route),
)

const AuthAdminUsersIdEditRoute = AuthAdminUsersIdEditImport.update({
  id: '/users/$id/edit',
  path: '/users/$id/edit',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/users/$id.edit.lazy').then((d) => d.Route),
)

const AuthAdminTubesIdEditRoute = AuthAdminTubesIdEditImport.update({
  id: '/tubes/$id/edit',
  path: '/tubes/$id/edit',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/tubes/$id.edit.lazy').then((d) => d.Route),
)

const AuthAdminResultsIdEditRoute = AuthAdminResultsIdEditImport.update({
  id: '/results/$id/edit',
  path: '/results/$id/edit',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/results/$id.edit.lazy').then((d) => d.Route),
)

const AuthAdminPodsIdEditRoute = AuthAdminPodsIdEditImport.update({
  id: '/pods/$id/edit',
  path: '/pods/$id/edit',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/pods/$id.edit.lazy').then((d) => d.Route),
)

const AuthAdminPanelsIdEditRoute = AuthAdminPanelsIdEditImport.update({
  id: '/panels/$id/edit',
  path: '/panels/$id/edit',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/panels/$id.edit.lazy').then((d) => d.Route),
)

const AuthAdminLabsIdEditRoute = AuthAdminLabsIdEditImport.update({
  id: '/labs/$id/edit',
  path: '/labs/$id/edit',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/labs/$id.edit.lazy').then((d) => d.Route),
)

const AuthAdminInstrumentsIdEditRoute = AuthAdminInstrumentsIdEditImport.update(
  {
    id: '/instruments/$id/edit',
    path: '/instruments/$id/edit',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any,
).lazy(() =>
  import('./routes/_auth.admin/instruments/$id.edit.lazy').then((d) => d.Route),
)

const AuthAdminClientsIdEditRoute = AuthAdminClientsIdEditImport.update({
  id: '/clients/$id/edit',
  path: '/clients/$id/edit',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/clients/$id.edit.lazy').then((d) => d.Route),
)

const AuthAppSubjectsIdReportRoute = AuthAppSubjectsIdReportImport.update({
  id: '/report',
  path: '/report',
  getParentRoute: () => AuthAppSubjectsIdRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/subjects.$id/report.lazy').then((d) => d.Route),
)

const AuthAppSubjectsIdCreateRoute = AuthAppSubjectsIdCreateImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => AuthAppSubjectsIdRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/subjects.$id/create.lazy').then((d) => d.Route),
)

const AuthAppSubjectsIdAccessionRoute = AuthAppSubjectsIdAccessionImport.update(
  {
    id: '/accession',
    path: '/accession',
    getParentRoute: () => AuthAppSubjectsIdRouteRoute,
  } as any,
).lazy(() =>
  import('./routes/_auth._app/subjects.$id/accession.lazy').then(
    (d) => d.Route,
  ),
)

const AuthAppAccessionsIdReportRoute = AuthAppAccessionsIdReportImport.update({
  id: '/accessions_/$id/report',
  path: '/accessions/$id/report',
  getParentRoute: () => AuthAppRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/accessions_.$id/report.lazy').then(
    (d) => d.Route,
  ),
)

const AuthAppAccessionsIdModalRoute = AuthAppAccessionsIdModalImport.update({
  id: '/$id/modal',
  path: '/$id/modal',
  getParentRoute: () => AuthAppAccessionsRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth._app/accessions/$id.modal.lazy').then((d) => d.Route),
)

const AuthAdminTestsIdEditIndexRoute = AuthAdminTestsIdEditIndexImport.update({
  id: '/tests/$id/edit/',
  path: '/tests/$id/edit/',
  getParentRoute: () => AuthAdminRouteRoute,
} as any).lazy(() =>
  import('./routes/_auth.admin/tests/$id.edit.index.lazy').then((d) => d.Route),
)

const AuthAdminTubesFieldsIdEditRoute = AuthAdminTubesFieldsIdEditImport.update(
  {
    id: '/tubes/fields/$id/edit',
    path: '/tubes/fields/$id/edit',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any,
).lazy(() =>
  import('./routes/_auth.admin/tubes/fields.$id.edit.lazy').then(
    (d) => d.Route,
  ),
)

const AuthAdminTubesFieldSetsIdEditRoute =
  AuthAdminTubesFieldSetsIdEditImport.update({
    id: '/tubes/field-sets/$id/edit',
    path: '/tubes/field-sets/$id/edit',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any).lazy(() =>
    import('./routes/_auth.admin/tubes/field-sets.$id.edit.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthAdminAuthProvidersIdEditRoute =
  AuthAdminAuthProvidersIdEditImport.update({
    id: '/auth/providers/$id/edit',
    path: '/auth/providers/$id/edit',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any).lazy(() =>
    import('./routes/_auth.admin/auth/providers.$id.edit.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthAppRunsIdWorklistTestIdRoute =
  AuthAppRunsIdWorklistTestIdImport.update({
    id: '/$id/worklist/$testId',
    path: '/$id/worklist/$testId',
    getParentRoute: () => AuthAppRunsRouteRoute,
  } as any).lazy(() =>
    import('./routes/_auth._app/runs/$id.worklist.$testId.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthAdminTestsIdEditResultsSpecIdRoute =
  AuthAdminTestsIdEditResultsSpecIdImport.update({
    id: '/tests/$id/edit/results/$specId',
    path: '/tests/$id/edit/results/$specId',
    getParentRoute: () => AuthAdminRouteRoute,
  } as any).lazy(() =>
    import('./routes/_auth.admin/tests/$id.edit.results.$specId.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthAppRunsIdWorklistTestIdResultsOrderIdRoute =
  AuthAppRunsIdWorklistTestIdResultsOrderIdImport.update({
    id: '/results/$orderId',
    path: '/results/$orderId',
    getParentRoute: () => AuthAppRunsIdWorklistTestIdRoute,
  } as any).lazy(() =>
    import(
      './routes/_auth._app/runs/$id.worklist.$testId.results.$orderId.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/error': {
      id: '/error'
      path: '/error'
      fullPath: '/error'
      preLoaderRoute: typeof ErrorImport
      parentRoute: typeof rootRoute
    }
    '/health': {
      id: '/health'
      path: '/health'
      fullPath: '/health'
      preLoaderRoute: typeof HealthImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_auth/admin': {
      id: '/_auth/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AuthAdminRouteImport
      parentRoute: typeof AuthImport
    }
    '/_auth/_app': {
      id: '/_auth/_app'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthAppRouteLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/_app/accessions': {
      id: '/_auth/_app/accessions'
      path: '/accessions'
      fullPath: '/accessions'
      preLoaderRoute: typeof AuthAppAccessionsRouteImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/_app/reports': {
      id: '/_auth/_app/reports'
      path: '/reports'
      fullPath: '/reports'
      preLoaderRoute: typeof AuthAppReportsRouteImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/_app/runs': {
      id: '/_auth/_app/runs'
      path: '/runs'
      fullPath: '/runs'
      preLoaderRoute: typeof AuthAppRunsRouteImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/_app/board': {
      id: '/_auth/_app/board'
      path: '/board'
      fullPath: '/board'
      preLoaderRoute: typeof AuthAppBoardRouteLazyImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/_app/': {
      id: '/_auth/_app/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthAppIndexImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/admin/': {
      id: '/_auth/admin/'
      path: '/'
      fullPath: '/admin/'
      preLoaderRoute: typeof AuthAdminIndexImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/_app/subjects/$id': {
      id: '/_auth/_app/subjects/$id'
      path: '/subjects/$id'
      fullPath: '/subjects/$id'
      preLoaderRoute: typeof AuthAppSubjectsIdRouteImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/_app/accessions/new': {
      id: '/_auth/_app/accessions/new'
      path: '/new'
      fullPath: '/accessions/new'
      preLoaderRoute: typeof AuthAppAccessionsNewImport
      parentRoute: typeof AuthAppAccessionsRouteImport
    }
    '/_auth/_app/accessions/newv2': {
      id: '/_auth/_app/accessions/newv2'
      path: '/newv2'
      fullPath: '/accessions/newv2'
      preLoaderRoute: typeof AuthAppAccessionsNewv2Import
      parentRoute: typeof AuthAppAccessionsRouteImport
    }
    '/_auth/_app/board/$id': {
      id: '/_auth/_app/board/$id'
      path: '/$id'
      fullPath: '/board/$id'
      preLoaderRoute: typeof AuthAppBoardIdImport
      parentRoute: typeof AuthAppBoardRouteLazyImport
    }
    '/_auth/_app/help/notifications': {
      id: '/_auth/_app/help/notifications'
      path: '/help/notifications'
      fullPath: '/help/notifications'
      preLoaderRoute: typeof AuthAppHelpNotificationsImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/_app/auth/profile': {
      id: '/_auth/_app/auth/profile'
      path: '/auth/profile'
      fullPath: '/auth/profile'
      preLoaderRoute: typeof AuthAppAuthProfileLazyImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/admin/clients/new': {
      id: '/_auth/admin/clients/new'
      path: '/clients/new'
      fullPath: '/admin/clients/new'
      preLoaderRoute: typeof AuthAdminClientsNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/instruments/new': {
      id: '/_auth/admin/instruments/new'
      path: '/instruments/new'
      fullPath: '/admin/instruments/new'
      preLoaderRoute: typeof AuthAdminInstrumentsNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/labs/new': {
      id: '/_auth/admin/labs/new'
      path: '/labs/new'
      fullPath: '/admin/labs/new'
      preLoaderRoute: typeof AuthAdminLabsNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/panels/new': {
      id: '/_auth/admin/panels/new'
      path: '/panels/new'
      fullPath: '/admin/panels/new'
      preLoaderRoute: typeof AuthAdminPanelsNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/pods/new': {
      id: '/_auth/admin/pods/new'
      path: '/pods/new'
      fullPath: '/admin/pods/new'
      preLoaderRoute: typeof AuthAdminPodsNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/results/new': {
      id: '/_auth/admin/results/new'
      path: '/results/new'
      fullPath: '/admin/results/new'
      preLoaderRoute: typeof AuthAdminResultsNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/tests/new': {
      id: '/_auth/admin/tests/new'
      path: '/tests/new'
      fullPath: '/admin/tests/new'
      preLoaderRoute: typeof AuthAdminTestsNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/tubes/new': {
      id: '/_auth/admin/tubes/new'
      path: '/tubes/new'
      fullPath: '/admin/tubes/new'
      preLoaderRoute: typeof AuthAdminTubesNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/workflows/new': {
      id: '/_auth/admin/workflows/new'
      path: '/workflows/new'
      fullPath: '/admin/workflows/new'
      preLoaderRoute: typeof AuthAdminWorkflowsNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/_app/accessions/': {
      id: '/_auth/_app/accessions/'
      path: '/'
      fullPath: '/accessions/'
      preLoaderRoute: typeof AuthAppAccessionsIndexLazyImport
      parentRoute: typeof AuthAppAccessionsRouteImport
    }
    '/_auth/_app/help/': {
      id: '/_auth/_app/help/'
      path: '/help'
      fullPath: '/help'
      preLoaderRoute: typeof AuthAppHelpIndexLazyImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/_app/reports/': {
      id: '/_auth/_app/reports/'
      path: '/'
      fullPath: '/reports/'
      preLoaderRoute: typeof AuthAppReportsIndexLazyImport
      parentRoute: typeof AuthAppReportsRouteImport
    }
    '/_auth/_app/runs/': {
      id: '/_auth/_app/runs/'
      path: '/'
      fullPath: '/runs/'
      preLoaderRoute: typeof AuthAppRunsIndexLazyImport
      parentRoute: typeof AuthAppRunsRouteImport
    }
    '/_auth/_app/search/': {
      id: '/_auth/_app/search/'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof AuthAppSearchIndexLazyImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/admin/audit/': {
      id: '/_auth/admin/audit/'
      path: '/audit'
      fullPath: '/admin/audit'
      preLoaderRoute: typeof AuthAdminAuditIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/auth/': {
      id: '/_auth/admin/auth/'
      path: '/auth'
      fullPath: '/admin/auth'
      preLoaderRoute: typeof AuthAdminAuthIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/clients/': {
      id: '/_auth/admin/clients/'
      path: '/clients'
      fullPath: '/admin/clients'
      preLoaderRoute: typeof AuthAdminClientsIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/instruments/': {
      id: '/_auth/admin/instruments/'
      path: '/instruments'
      fullPath: '/admin/instruments'
      preLoaderRoute: typeof AuthAdminInstrumentsIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/labs/': {
      id: '/_auth/admin/labs/'
      path: '/labs'
      fullPath: '/admin/labs'
      preLoaderRoute: typeof AuthAdminLabsIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/panels/': {
      id: '/_auth/admin/panels/'
      path: '/panels'
      fullPath: '/admin/panels'
      preLoaderRoute: typeof AuthAdminPanelsIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/pods/': {
      id: '/_auth/admin/pods/'
      path: '/pods'
      fullPath: '/admin/pods'
      preLoaderRoute: typeof AuthAdminPodsIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/results/': {
      id: '/_auth/admin/results/'
      path: '/results'
      fullPath: '/admin/results'
      preLoaderRoute: typeof AuthAdminResultsIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/settings/': {
      id: '/_auth/admin/settings/'
      path: '/settings'
      fullPath: '/admin/settings'
      preLoaderRoute: typeof AuthAdminSettingsIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/tests/': {
      id: '/_auth/admin/tests/'
      path: '/tests'
      fullPath: '/admin/tests'
      preLoaderRoute: typeof AuthAdminTestsIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/tubes/': {
      id: '/_auth/admin/tubes/'
      path: '/tubes'
      fullPath: '/admin/tubes'
      preLoaderRoute: typeof AuthAdminTubesIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/users/': {
      id: '/_auth/admin/users/'
      path: '/users'
      fullPath: '/admin/users'
      preLoaderRoute: typeof AuthAdminUsersIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/workflows/': {
      id: '/_auth/admin/workflows/'
      path: '/workflows'
      fullPath: '/admin/workflows'
      preLoaderRoute: typeof AuthAdminWorkflowsIndexLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/_app/accessions/$id/modal': {
      id: '/_auth/_app/accessions/$id/modal'
      path: '/$id/modal'
      fullPath: '/accessions/$id/modal'
      preLoaderRoute: typeof AuthAppAccessionsIdModalImport
      parentRoute: typeof AuthAppAccessionsRouteImport
    }
    '/_auth/_app/accessions_/$id/report': {
      id: '/_auth/_app/accessions_/$id/report'
      path: '/accessions/$id/report'
      fullPath: '/accessions/$id/report'
      preLoaderRoute: typeof AuthAppAccessionsIdReportImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/_app/subjects/$id/accession': {
      id: '/_auth/_app/subjects/$id/accession'
      path: '/accession'
      fullPath: '/subjects/$id/accession'
      preLoaderRoute: typeof AuthAppSubjectsIdAccessionImport
      parentRoute: typeof AuthAppSubjectsIdRouteImport
    }
    '/_auth/_app/subjects/$id/create': {
      id: '/_auth/_app/subjects/$id/create'
      path: '/create'
      fullPath: '/subjects/$id/create'
      preLoaderRoute: typeof AuthAppSubjectsIdCreateImport
      parentRoute: typeof AuthAppSubjectsIdRouteImport
    }
    '/_auth/_app/subjects/$id/report': {
      id: '/_auth/_app/subjects/$id/report'
      path: '/report'
      fullPath: '/subjects/$id/report'
      preLoaderRoute: typeof AuthAppSubjectsIdReportImport
      parentRoute: typeof AuthAppSubjectsIdRouteImport
    }
    '/_auth/admin/clients/$id/edit': {
      id: '/_auth/admin/clients/$id/edit'
      path: '/clients/$id/edit'
      fullPath: '/admin/clients/$id/edit'
      preLoaderRoute: typeof AuthAdminClientsIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/instruments/$id/edit': {
      id: '/_auth/admin/instruments/$id/edit'
      path: '/instruments/$id/edit'
      fullPath: '/admin/instruments/$id/edit'
      preLoaderRoute: typeof AuthAdminInstrumentsIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/labs/$id/edit': {
      id: '/_auth/admin/labs/$id/edit'
      path: '/labs/$id/edit'
      fullPath: '/admin/labs/$id/edit'
      preLoaderRoute: typeof AuthAdminLabsIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/panels/$id/edit': {
      id: '/_auth/admin/panels/$id/edit'
      path: '/panels/$id/edit'
      fullPath: '/admin/panels/$id/edit'
      preLoaderRoute: typeof AuthAdminPanelsIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/pods/$id/edit': {
      id: '/_auth/admin/pods/$id/edit'
      path: '/pods/$id/edit'
      fullPath: '/admin/pods/$id/edit'
      preLoaderRoute: typeof AuthAdminPodsIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/results/$id/edit': {
      id: '/_auth/admin/results/$id/edit'
      path: '/results/$id/edit'
      fullPath: '/admin/results/$id/edit'
      preLoaderRoute: typeof AuthAdminResultsIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/tubes/$id/edit': {
      id: '/_auth/admin/tubes/$id/edit'
      path: '/tubes/$id/edit'
      fullPath: '/admin/tubes/$id/edit'
      preLoaderRoute: typeof AuthAdminTubesIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/users/$id/edit': {
      id: '/_auth/admin/users/$id/edit'
      path: '/users/$id/edit'
      fullPath: '/admin/users/$id/edit'
      preLoaderRoute: typeof AuthAdminUsersIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/workflows/$id/edit': {
      id: '/_auth/admin/workflows/$id/edit'
      path: '/workflows/$id/edit'
      fullPath: '/admin/workflows/$id/edit'
      preLoaderRoute: typeof AuthAdminWorkflowsIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/auth/providers/new': {
      id: '/_auth/admin/auth/providers/new'
      path: '/auth/providers/new'
      fullPath: '/admin/auth/providers/new'
      preLoaderRoute: typeof AuthAdminAuthProvidersNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/tubes/field-sets/new': {
      id: '/_auth/admin/tubes/field-sets/new'
      path: '/tubes/field-sets/new'
      fullPath: '/admin/tubes/field-sets/new'
      preLoaderRoute: typeof AuthAdminTubesFieldSetsNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/tubes/fields/new': {
      id: '/_auth/admin/tubes/fields/new'
      path: '/tubes/fields/new'
      fullPath: '/admin/tubes/fields/new'
      preLoaderRoute: typeof AuthAdminTubesFieldsNewLazyImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/_app/accessions_/$id/': {
      id: '/_auth/_app/accessions_/$id/'
      path: '/accessions/$id'
      fullPath: '/accessions/$id'
      preLoaderRoute: typeof AuthAppAccessionsIdIndexImport
      parentRoute: typeof AuthAppRouteLazyImport
    }
    '/_auth/_app/runs/$id/': {
      id: '/_auth/_app/runs/$id/'
      path: '/$id'
      fullPath: '/runs/$id'
      preLoaderRoute: typeof AuthAppRunsIdIndexImport
      parentRoute: typeof AuthAppRunsRouteImport
    }
    '/_auth/_app/runs/$id/worklist/$testId': {
      id: '/_auth/_app/runs/$id/worklist/$testId'
      path: '/$id/worklist/$testId'
      fullPath: '/runs/$id/worklist/$testId'
      preLoaderRoute: typeof AuthAppRunsIdWorklistTestIdImport
      parentRoute: typeof AuthAppRunsRouteImport
    }
    '/_auth/admin/auth/providers/$id/edit': {
      id: '/_auth/admin/auth/providers/$id/edit'
      path: '/auth/providers/$id/edit'
      fullPath: '/admin/auth/providers/$id/edit'
      preLoaderRoute: typeof AuthAdminAuthProvidersIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/tubes/field-sets/$id/edit': {
      id: '/_auth/admin/tubes/field-sets/$id/edit'
      path: '/tubes/field-sets/$id/edit'
      fullPath: '/admin/tubes/field-sets/$id/edit'
      preLoaderRoute: typeof AuthAdminTubesFieldSetsIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/tubes/fields/$id/edit': {
      id: '/_auth/admin/tubes/fields/$id/edit'
      path: '/tubes/fields/$id/edit'
      fullPath: '/admin/tubes/fields/$id/edit'
      preLoaderRoute: typeof AuthAdminTubesFieldsIdEditImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/tests/$id/edit/': {
      id: '/_auth/admin/tests/$id/edit/'
      path: '/tests/$id/edit'
      fullPath: '/admin/tests/$id/edit'
      preLoaderRoute: typeof AuthAdminTestsIdEditIndexImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/admin/tests/$id/edit/results/$specId': {
      id: '/_auth/admin/tests/$id/edit/results/$specId'
      path: '/tests/$id/edit/results/$specId'
      fullPath: '/admin/tests/$id/edit/results/$specId'
      preLoaderRoute: typeof AuthAdminTestsIdEditResultsSpecIdImport
      parentRoute: typeof AuthAdminRouteImport
    }
    '/_auth/_app/runs/$id/worklist/$testId/results/$orderId': {
      id: '/_auth/_app/runs/$id/worklist/$testId/results/$orderId'
      path: '/results/$orderId'
      fullPath: '/runs/$id/worklist/$testId/results/$orderId'
      preLoaderRoute: typeof AuthAppRunsIdWorklistTestIdResultsOrderIdImport
      parentRoute: typeof AuthAppRunsIdWorklistTestIdImport
    }
  }
}

// Create and export the route tree

interface AuthAdminRouteRouteChildren {
  AuthAdminIndexRoute: typeof AuthAdminIndexRoute
  AuthAdminClientsNewLazyRoute: typeof AuthAdminClientsNewLazyRoute
  AuthAdminInstrumentsNewLazyRoute: typeof AuthAdminInstrumentsNewLazyRoute
  AuthAdminLabsNewLazyRoute: typeof AuthAdminLabsNewLazyRoute
  AuthAdminPanelsNewLazyRoute: typeof AuthAdminPanelsNewLazyRoute
  AuthAdminPodsNewLazyRoute: typeof AuthAdminPodsNewLazyRoute
  AuthAdminResultsNewLazyRoute: typeof AuthAdminResultsNewLazyRoute
  AuthAdminTestsNewLazyRoute: typeof AuthAdminTestsNewLazyRoute
  AuthAdminTubesNewLazyRoute: typeof AuthAdminTubesNewLazyRoute
  AuthAdminWorkflowsNewLazyRoute: typeof AuthAdminWorkflowsNewLazyRoute
  AuthAdminAuditIndexLazyRoute: typeof AuthAdminAuditIndexLazyRoute
  AuthAdminAuthIndexLazyRoute: typeof AuthAdminAuthIndexLazyRoute
  AuthAdminClientsIndexLazyRoute: typeof AuthAdminClientsIndexLazyRoute
  AuthAdminInstrumentsIndexLazyRoute: typeof AuthAdminInstrumentsIndexLazyRoute
  AuthAdminLabsIndexLazyRoute: typeof AuthAdminLabsIndexLazyRoute
  AuthAdminPanelsIndexLazyRoute: typeof AuthAdminPanelsIndexLazyRoute
  AuthAdminPodsIndexLazyRoute: typeof AuthAdminPodsIndexLazyRoute
  AuthAdminResultsIndexLazyRoute: typeof AuthAdminResultsIndexLazyRoute
  AuthAdminSettingsIndexLazyRoute: typeof AuthAdminSettingsIndexLazyRoute
  AuthAdminTestsIndexLazyRoute: typeof AuthAdminTestsIndexLazyRoute
  AuthAdminTubesIndexLazyRoute: typeof AuthAdminTubesIndexLazyRoute
  AuthAdminUsersIndexLazyRoute: typeof AuthAdminUsersIndexLazyRoute
  AuthAdminWorkflowsIndexLazyRoute: typeof AuthAdminWorkflowsIndexLazyRoute
  AuthAdminClientsIdEditRoute: typeof AuthAdminClientsIdEditRoute
  AuthAdminInstrumentsIdEditRoute: typeof AuthAdminInstrumentsIdEditRoute
  AuthAdminLabsIdEditRoute: typeof AuthAdminLabsIdEditRoute
  AuthAdminPanelsIdEditRoute: typeof AuthAdminPanelsIdEditRoute
  AuthAdminPodsIdEditRoute: typeof AuthAdminPodsIdEditRoute
  AuthAdminResultsIdEditRoute: typeof AuthAdminResultsIdEditRoute
  AuthAdminTubesIdEditRoute: typeof AuthAdminTubesIdEditRoute
  AuthAdminUsersIdEditRoute: typeof AuthAdminUsersIdEditRoute
  AuthAdminWorkflowsIdEditRoute: typeof AuthAdminWorkflowsIdEditRoute
  AuthAdminAuthProvidersNewLazyRoute: typeof AuthAdminAuthProvidersNewLazyRoute
  AuthAdminTubesFieldSetsNewLazyRoute: typeof AuthAdminTubesFieldSetsNewLazyRoute
  AuthAdminTubesFieldsNewLazyRoute: typeof AuthAdminTubesFieldsNewLazyRoute
  AuthAdminAuthProvidersIdEditRoute: typeof AuthAdminAuthProvidersIdEditRoute
  AuthAdminTubesFieldSetsIdEditRoute: typeof AuthAdminTubesFieldSetsIdEditRoute
  AuthAdminTubesFieldsIdEditRoute: typeof AuthAdminTubesFieldsIdEditRoute
  AuthAdminTestsIdEditIndexRoute: typeof AuthAdminTestsIdEditIndexRoute
  AuthAdminTestsIdEditResultsSpecIdRoute: typeof AuthAdminTestsIdEditResultsSpecIdRoute
}

const AuthAdminRouteRouteChildren: AuthAdminRouteRouteChildren = {
  AuthAdminIndexRoute: AuthAdminIndexRoute,
  AuthAdminClientsNewLazyRoute: AuthAdminClientsNewLazyRoute,
  AuthAdminInstrumentsNewLazyRoute: AuthAdminInstrumentsNewLazyRoute,
  AuthAdminLabsNewLazyRoute: AuthAdminLabsNewLazyRoute,
  AuthAdminPanelsNewLazyRoute: AuthAdminPanelsNewLazyRoute,
  AuthAdminPodsNewLazyRoute: AuthAdminPodsNewLazyRoute,
  AuthAdminResultsNewLazyRoute: AuthAdminResultsNewLazyRoute,
  AuthAdminTestsNewLazyRoute: AuthAdminTestsNewLazyRoute,
  AuthAdminTubesNewLazyRoute: AuthAdminTubesNewLazyRoute,
  AuthAdminWorkflowsNewLazyRoute: AuthAdminWorkflowsNewLazyRoute,
  AuthAdminAuditIndexLazyRoute: AuthAdminAuditIndexLazyRoute,
  AuthAdminAuthIndexLazyRoute: AuthAdminAuthIndexLazyRoute,
  AuthAdminClientsIndexLazyRoute: AuthAdminClientsIndexLazyRoute,
  AuthAdminInstrumentsIndexLazyRoute: AuthAdminInstrumentsIndexLazyRoute,
  AuthAdminLabsIndexLazyRoute: AuthAdminLabsIndexLazyRoute,
  AuthAdminPanelsIndexLazyRoute: AuthAdminPanelsIndexLazyRoute,
  AuthAdminPodsIndexLazyRoute: AuthAdminPodsIndexLazyRoute,
  AuthAdminResultsIndexLazyRoute: AuthAdminResultsIndexLazyRoute,
  AuthAdminSettingsIndexLazyRoute: AuthAdminSettingsIndexLazyRoute,
  AuthAdminTestsIndexLazyRoute: AuthAdminTestsIndexLazyRoute,
  AuthAdminTubesIndexLazyRoute: AuthAdminTubesIndexLazyRoute,
  AuthAdminUsersIndexLazyRoute: AuthAdminUsersIndexLazyRoute,
  AuthAdminWorkflowsIndexLazyRoute: AuthAdminWorkflowsIndexLazyRoute,
  AuthAdminClientsIdEditRoute: AuthAdminClientsIdEditRoute,
  AuthAdminInstrumentsIdEditRoute: AuthAdminInstrumentsIdEditRoute,
  AuthAdminLabsIdEditRoute: AuthAdminLabsIdEditRoute,
  AuthAdminPanelsIdEditRoute: AuthAdminPanelsIdEditRoute,
  AuthAdminPodsIdEditRoute: AuthAdminPodsIdEditRoute,
  AuthAdminResultsIdEditRoute: AuthAdminResultsIdEditRoute,
  AuthAdminTubesIdEditRoute: AuthAdminTubesIdEditRoute,
  AuthAdminUsersIdEditRoute: AuthAdminUsersIdEditRoute,
  AuthAdminWorkflowsIdEditRoute: AuthAdminWorkflowsIdEditRoute,
  AuthAdminAuthProvidersNewLazyRoute: AuthAdminAuthProvidersNewLazyRoute,
  AuthAdminTubesFieldSetsNewLazyRoute: AuthAdminTubesFieldSetsNewLazyRoute,
  AuthAdminTubesFieldsNewLazyRoute: AuthAdminTubesFieldsNewLazyRoute,
  AuthAdminAuthProvidersIdEditRoute: AuthAdminAuthProvidersIdEditRoute,
  AuthAdminTubesFieldSetsIdEditRoute: AuthAdminTubesFieldSetsIdEditRoute,
  AuthAdminTubesFieldsIdEditRoute: AuthAdminTubesFieldsIdEditRoute,
  AuthAdminTestsIdEditIndexRoute: AuthAdminTestsIdEditIndexRoute,
  AuthAdminTestsIdEditResultsSpecIdRoute:
    AuthAdminTestsIdEditResultsSpecIdRoute,
}

const AuthAdminRouteRouteWithChildren = AuthAdminRouteRoute._addFileChildren(
  AuthAdminRouteRouteChildren,
)

interface AuthAppAccessionsRouteRouteChildren {
  AuthAppAccessionsNewRoute: typeof AuthAppAccessionsNewRoute
  AuthAppAccessionsNewv2Route: typeof AuthAppAccessionsNewv2Route
  AuthAppAccessionsIndexLazyRoute: typeof AuthAppAccessionsIndexLazyRoute
  AuthAppAccessionsIdModalRoute: typeof AuthAppAccessionsIdModalRoute
}

const AuthAppAccessionsRouteRouteChildren: AuthAppAccessionsRouteRouteChildren =
  {
    AuthAppAccessionsNewRoute: AuthAppAccessionsNewRoute,
    AuthAppAccessionsNewv2Route: AuthAppAccessionsNewv2Route,
    AuthAppAccessionsIndexLazyRoute: AuthAppAccessionsIndexLazyRoute,
    AuthAppAccessionsIdModalRoute: AuthAppAccessionsIdModalRoute,
  }

const AuthAppAccessionsRouteRouteWithChildren =
  AuthAppAccessionsRouteRoute._addFileChildren(
    AuthAppAccessionsRouteRouteChildren,
  )

interface AuthAppReportsRouteRouteChildren {
  AuthAppReportsIndexLazyRoute: typeof AuthAppReportsIndexLazyRoute
}

const AuthAppReportsRouteRouteChildren: AuthAppReportsRouteRouteChildren = {
  AuthAppReportsIndexLazyRoute: AuthAppReportsIndexLazyRoute,
}

const AuthAppReportsRouteRouteWithChildren =
  AuthAppReportsRouteRoute._addFileChildren(AuthAppReportsRouteRouteChildren)

interface AuthAppRunsIdWorklistTestIdRouteChildren {
  AuthAppRunsIdWorklistTestIdResultsOrderIdRoute: typeof AuthAppRunsIdWorklistTestIdResultsOrderIdRoute
}

const AuthAppRunsIdWorklistTestIdRouteChildren: AuthAppRunsIdWorklistTestIdRouteChildren =
  {
    AuthAppRunsIdWorklistTestIdResultsOrderIdRoute:
      AuthAppRunsIdWorklistTestIdResultsOrderIdRoute,
  }

const AuthAppRunsIdWorklistTestIdRouteWithChildren =
  AuthAppRunsIdWorklistTestIdRoute._addFileChildren(
    AuthAppRunsIdWorklistTestIdRouteChildren,
  )

interface AuthAppRunsRouteRouteChildren {
  AuthAppRunsIndexLazyRoute: typeof AuthAppRunsIndexLazyRoute
  AuthAppRunsIdIndexRoute: typeof AuthAppRunsIdIndexRoute
  AuthAppRunsIdWorklistTestIdRoute: typeof AuthAppRunsIdWorklistTestIdRouteWithChildren
}

const AuthAppRunsRouteRouteChildren: AuthAppRunsRouteRouteChildren = {
  AuthAppRunsIndexLazyRoute: AuthAppRunsIndexLazyRoute,
  AuthAppRunsIdIndexRoute: AuthAppRunsIdIndexRoute,
  AuthAppRunsIdWorklistTestIdRoute:
    AuthAppRunsIdWorklistTestIdRouteWithChildren,
}

const AuthAppRunsRouteRouteWithChildren =
  AuthAppRunsRouteRoute._addFileChildren(AuthAppRunsRouteRouteChildren)

interface AuthAppBoardRouteLazyRouteChildren {
  AuthAppBoardIdRoute: typeof AuthAppBoardIdRoute
}

const AuthAppBoardRouteLazyRouteChildren: AuthAppBoardRouteLazyRouteChildren = {
  AuthAppBoardIdRoute: AuthAppBoardIdRoute,
}

const AuthAppBoardRouteLazyRouteWithChildren =
  AuthAppBoardRouteLazyRoute._addFileChildren(
    AuthAppBoardRouteLazyRouteChildren,
  )

interface AuthAppSubjectsIdRouteRouteChildren {
  AuthAppSubjectsIdAccessionRoute: typeof AuthAppSubjectsIdAccessionRoute
  AuthAppSubjectsIdCreateRoute: typeof AuthAppSubjectsIdCreateRoute
  AuthAppSubjectsIdReportRoute: typeof AuthAppSubjectsIdReportRoute
}

const AuthAppSubjectsIdRouteRouteChildren: AuthAppSubjectsIdRouteRouteChildren =
  {
    AuthAppSubjectsIdAccessionRoute: AuthAppSubjectsIdAccessionRoute,
    AuthAppSubjectsIdCreateRoute: AuthAppSubjectsIdCreateRoute,
    AuthAppSubjectsIdReportRoute: AuthAppSubjectsIdReportRoute,
  }

const AuthAppSubjectsIdRouteRouteWithChildren =
  AuthAppSubjectsIdRouteRoute._addFileChildren(
    AuthAppSubjectsIdRouteRouteChildren,
  )

interface AuthAppRouteLazyRouteChildren {
  AuthAppAccessionsRouteRoute: typeof AuthAppAccessionsRouteRouteWithChildren
  AuthAppReportsRouteRoute: typeof AuthAppReportsRouteRouteWithChildren
  AuthAppRunsRouteRoute: typeof AuthAppRunsRouteRouteWithChildren
  AuthAppBoardRouteLazyRoute: typeof AuthAppBoardRouteLazyRouteWithChildren
  AuthAppIndexRoute: typeof AuthAppIndexRoute
  AuthAppSubjectsIdRouteRoute: typeof AuthAppSubjectsIdRouteRouteWithChildren
  AuthAppHelpNotificationsRoute: typeof AuthAppHelpNotificationsRoute
  AuthAppAuthProfileLazyRoute: typeof AuthAppAuthProfileLazyRoute
  AuthAppHelpIndexLazyRoute: typeof AuthAppHelpIndexLazyRoute
  AuthAppSearchIndexLazyRoute: typeof AuthAppSearchIndexLazyRoute
  AuthAppAccessionsIdReportRoute: typeof AuthAppAccessionsIdReportRoute
  AuthAppAccessionsIdIndexRoute: typeof AuthAppAccessionsIdIndexRoute
}

const AuthAppRouteLazyRouteChildren: AuthAppRouteLazyRouteChildren = {
  AuthAppAccessionsRouteRoute: AuthAppAccessionsRouteRouteWithChildren,
  AuthAppReportsRouteRoute: AuthAppReportsRouteRouteWithChildren,
  AuthAppRunsRouteRoute: AuthAppRunsRouteRouteWithChildren,
  AuthAppBoardRouteLazyRoute: AuthAppBoardRouteLazyRouteWithChildren,
  AuthAppIndexRoute: AuthAppIndexRoute,
  AuthAppSubjectsIdRouteRoute: AuthAppSubjectsIdRouteRouteWithChildren,
  AuthAppHelpNotificationsRoute: AuthAppHelpNotificationsRoute,
  AuthAppAuthProfileLazyRoute: AuthAppAuthProfileLazyRoute,
  AuthAppHelpIndexLazyRoute: AuthAppHelpIndexLazyRoute,
  AuthAppSearchIndexLazyRoute: AuthAppSearchIndexLazyRoute,
  AuthAppAccessionsIdReportRoute: AuthAppAccessionsIdReportRoute,
  AuthAppAccessionsIdIndexRoute: AuthAppAccessionsIdIndexRoute,
}

const AuthAppRouteLazyRouteWithChildren =
  AuthAppRouteLazyRoute._addFileChildren(AuthAppRouteLazyRouteChildren)

interface AuthRouteChildren {
  AuthAdminRouteRoute: typeof AuthAdminRouteRouteWithChildren
  AuthAppRouteLazyRoute: typeof AuthAppRouteLazyRouteWithChildren
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthAdminRouteRoute: AuthAdminRouteRouteWithChildren,
  AuthAppRouteLazyRoute: AuthAppRouteLazyRouteWithChildren,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthAppRouteLazyRouteWithChildren
  '/error': typeof ErrorRoute
  '/health': typeof HealthRoute
  '/login': typeof LoginRoute
  '/admin': typeof AuthAdminRouteRouteWithChildren
  '/accessions': typeof AuthAppAccessionsRouteRouteWithChildren
  '/reports': typeof AuthAppReportsRouteRouteWithChildren
  '/runs': typeof AuthAppRunsRouteRouteWithChildren
  '/board': typeof AuthAppBoardRouteLazyRouteWithChildren
  '/': typeof AuthAppIndexRoute
  '/admin/': typeof AuthAdminIndexRoute
  '/subjects/$id': typeof AuthAppSubjectsIdRouteRouteWithChildren
  '/accessions/new': typeof AuthAppAccessionsNewRoute
  '/accessions/newv2': typeof AuthAppAccessionsNewv2Route
  '/board/$id': typeof AuthAppBoardIdRoute
  '/help/notifications': typeof AuthAppHelpNotificationsRoute
  '/auth/profile': typeof AuthAppAuthProfileLazyRoute
  '/admin/clients/new': typeof AuthAdminClientsNewLazyRoute
  '/admin/instruments/new': typeof AuthAdminInstrumentsNewLazyRoute
  '/admin/labs/new': typeof AuthAdminLabsNewLazyRoute
  '/admin/panels/new': typeof AuthAdminPanelsNewLazyRoute
  '/admin/pods/new': typeof AuthAdminPodsNewLazyRoute
  '/admin/results/new': typeof AuthAdminResultsNewLazyRoute
  '/admin/tests/new': typeof AuthAdminTestsNewLazyRoute
  '/admin/tubes/new': typeof AuthAdminTubesNewLazyRoute
  '/admin/workflows/new': typeof AuthAdminWorkflowsNewLazyRoute
  '/accessions/': typeof AuthAppAccessionsIndexLazyRoute
  '/help': typeof AuthAppHelpIndexLazyRoute
  '/reports/': typeof AuthAppReportsIndexLazyRoute
  '/runs/': typeof AuthAppRunsIndexLazyRoute
  '/search': typeof AuthAppSearchIndexLazyRoute
  '/admin/audit': typeof AuthAdminAuditIndexLazyRoute
  '/admin/auth': typeof AuthAdminAuthIndexLazyRoute
  '/admin/clients': typeof AuthAdminClientsIndexLazyRoute
  '/admin/instruments': typeof AuthAdminInstrumentsIndexLazyRoute
  '/admin/labs': typeof AuthAdminLabsIndexLazyRoute
  '/admin/panels': typeof AuthAdminPanelsIndexLazyRoute
  '/admin/pods': typeof AuthAdminPodsIndexLazyRoute
  '/admin/results': typeof AuthAdminResultsIndexLazyRoute
  '/admin/settings': typeof AuthAdminSettingsIndexLazyRoute
  '/admin/tests': typeof AuthAdminTestsIndexLazyRoute
  '/admin/tubes': typeof AuthAdminTubesIndexLazyRoute
  '/admin/users': typeof AuthAdminUsersIndexLazyRoute
  '/admin/workflows': typeof AuthAdminWorkflowsIndexLazyRoute
  '/accessions/$id/modal': typeof AuthAppAccessionsIdModalRoute
  '/accessions/$id/report': typeof AuthAppAccessionsIdReportRoute
  '/subjects/$id/accession': typeof AuthAppSubjectsIdAccessionRoute
  '/subjects/$id/create': typeof AuthAppSubjectsIdCreateRoute
  '/subjects/$id/report': typeof AuthAppSubjectsIdReportRoute
  '/admin/clients/$id/edit': typeof AuthAdminClientsIdEditRoute
  '/admin/instruments/$id/edit': typeof AuthAdminInstrumentsIdEditRoute
  '/admin/labs/$id/edit': typeof AuthAdminLabsIdEditRoute
  '/admin/panels/$id/edit': typeof AuthAdminPanelsIdEditRoute
  '/admin/pods/$id/edit': typeof AuthAdminPodsIdEditRoute
  '/admin/results/$id/edit': typeof AuthAdminResultsIdEditRoute
  '/admin/tubes/$id/edit': typeof AuthAdminTubesIdEditRoute
  '/admin/users/$id/edit': typeof AuthAdminUsersIdEditRoute
  '/admin/workflows/$id/edit': typeof AuthAdminWorkflowsIdEditRoute
  '/admin/auth/providers/new': typeof AuthAdminAuthProvidersNewLazyRoute
  '/admin/tubes/field-sets/new': typeof AuthAdminTubesFieldSetsNewLazyRoute
  '/admin/tubes/fields/new': typeof AuthAdminTubesFieldsNewLazyRoute
  '/accessions/$id': typeof AuthAppAccessionsIdIndexRoute
  '/runs/$id': typeof AuthAppRunsIdIndexRoute
  '/runs/$id/worklist/$testId': typeof AuthAppRunsIdWorklistTestIdRouteWithChildren
  '/admin/auth/providers/$id/edit': typeof AuthAdminAuthProvidersIdEditRoute
  '/admin/tubes/field-sets/$id/edit': typeof AuthAdminTubesFieldSetsIdEditRoute
  '/admin/tubes/fields/$id/edit': typeof AuthAdminTubesFieldsIdEditRoute
  '/admin/tests/$id/edit': typeof AuthAdminTestsIdEditIndexRoute
  '/admin/tests/$id/edit/results/$specId': typeof AuthAdminTestsIdEditResultsSpecIdRoute
  '/runs/$id/worklist/$testId/results/$orderId': typeof AuthAppRunsIdWorklistTestIdResultsOrderIdRoute
}

export interface FileRoutesByTo {
  '': typeof AuthRouteWithChildren
  '/error': typeof ErrorRoute
  '/health': typeof HealthRoute
  '/login': typeof LoginRoute
  '/board': typeof AuthAppBoardRouteLazyRouteWithChildren
  '/': typeof AuthAppIndexRoute
  '/admin': typeof AuthAdminIndexRoute
  '/subjects/$id': typeof AuthAppSubjectsIdRouteRouteWithChildren
  '/accessions/new': typeof AuthAppAccessionsNewRoute
  '/accessions/newv2': typeof AuthAppAccessionsNewv2Route
  '/board/$id': typeof AuthAppBoardIdRoute
  '/help/notifications': typeof AuthAppHelpNotificationsRoute
  '/auth/profile': typeof AuthAppAuthProfileLazyRoute
  '/admin/clients/new': typeof AuthAdminClientsNewLazyRoute
  '/admin/instruments/new': typeof AuthAdminInstrumentsNewLazyRoute
  '/admin/labs/new': typeof AuthAdminLabsNewLazyRoute
  '/admin/panels/new': typeof AuthAdminPanelsNewLazyRoute
  '/admin/pods/new': typeof AuthAdminPodsNewLazyRoute
  '/admin/results/new': typeof AuthAdminResultsNewLazyRoute
  '/admin/tests/new': typeof AuthAdminTestsNewLazyRoute
  '/admin/tubes/new': typeof AuthAdminTubesNewLazyRoute
  '/admin/workflows/new': typeof AuthAdminWorkflowsNewLazyRoute
  '/accessions': typeof AuthAppAccessionsIndexLazyRoute
  '/help': typeof AuthAppHelpIndexLazyRoute
  '/reports': typeof AuthAppReportsIndexLazyRoute
  '/runs': typeof AuthAppRunsIndexLazyRoute
  '/search': typeof AuthAppSearchIndexLazyRoute
  '/admin/audit': typeof AuthAdminAuditIndexLazyRoute
  '/admin/auth': typeof AuthAdminAuthIndexLazyRoute
  '/admin/clients': typeof AuthAdminClientsIndexLazyRoute
  '/admin/instruments': typeof AuthAdminInstrumentsIndexLazyRoute
  '/admin/labs': typeof AuthAdminLabsIndexLazyRoute
  '/admin/panels': typeof AuthAdminPanelsIndexLazyRoute
  '/admin/pods': typeof AuthAdminPodsIndexLazyRoute
  '/admin/results': typeof AuthAdminResultsIndexLazyRoute
  '/admin/settings': typeof AuthAdminSettingsIndexLazyRoute
  '/admin/tests': typeof AuthAdminTestsIndexLazyRoute
  '/admin/tubes': typeof AuthAdminTubesIndexLazyRoute
  '/admin/users': typeof AuthAdminUsersIndexLazyRoute
  '/admin/workflows': typeof AuthAdminWorkflowsIndexLazyRoute
  '/accessions/$id/modal': typeof AuthAppAccessionsIdModalRoute
  '/accessions/$id/report': typeof AuthAppAccessionsIdReportRoute
  '/subjects/$id/accession': typeof AuthAppSubjectsIdAccessionRoute
  '/subjects/$id/create': typeof AuthAppSubjectsIdCreateRoute
  '/subjects/$id/report': typeof AuthAppSubjectsIdReportRoute
  '/admin/clients/$id/edit': typeof AuthAdminClientsIdEditRoute
  '/admin/instruments/$id/edit': typeof AuthAdminInstrumentsIdEditRoute
  '/admin/labs/$id/edit': typeof AuthAdminLabsIdEditRoute
  '/admin/panels/$id/edit': typeof AuthAdminPanelsIdEditRoute
  '/admin/pods/$id/edit': typeof AuthAdminPodsIdEditRoute
  '/admin/results/$id/edit': typeof AuthAdminResultsIdEditRoute
  '/admin/tubes/$id/edit': typeof AuthAdminTubesIdEditRoute
  '/admin/users/$id/edit': typeof AuthAdminUsersIdEditRoute
  '/admin/workflows/$id/edit': typeof AuthAdminWorkflowsIdEditRoute
  '/admin/auth/providers/new': typeof AuthAdminAuthProvidersNewLazyRoute
  '/admin/tubes/field-sets/new': typeof AuthAdminTubesFieldSetsNewLazyRoute
  '/admin/tubes/fields/new': typeof AuthAdminTubesFieldsNewLazyRoute
  '/accessions/$id': typeof AuthAppAccessionsIdIndexRoute
  '/runs/$id': typeof AuthAppRunsIdIndexRoute
  '/runs/$id/worklist/$testId': typeof AuthAppRunsIdWorklistTestIdRouteWithChildren
  '/admin/auth/providers/$id/edit': typeof AuthAdminAuthProvidersIdEditRoute
  '/admin/tubes/field-sets/$id/edit': typeof AuthAdminTubesFieldSetsIdEditRoute
  '/admin/tubes/fields/$id/edit': typeof AuthAdminTubesFieldsIdEditRoute
  '/admin/tests/$id/edit': typeof AuthAdminTestsIdEditIndexRoute
  '/admin/tests/$id/edit/results/$specId': typeof AuthAdminTestsIdEditResultsSpecIdRoute
  '/runs/$id/worklist/$testId/results/$orderId': typeof AuthAppRunsIdWorklistTestIdResultsOrderIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/error': typeof ErrorRoute
  '/health': typeof HealthRoute
  '/login': typeof LoginRoute
  '/_auth/admin': typeof AuthAdminRouteRouteWithChildren
  '/_auth/_app': typeof AuthAppRouteLazyRouteWithChildren
  '/_auth/_app/accessions': typeof AuthAppAccessionsRouteRouteWithChildren
  '/_auth/_app/reports': typeof AuthAppReportsRouteRouteWithChildren
  '/_auth/_app/runs': typeof AuthAppRunsRouteRouteWithChildren
  '/_auth/_app/board': typeof AuthAppBoardRouteLazyRouteWithChildren
  '/_auth/_app/': typeof AuthAppIndexRoute
  '/_auth/admin/': typeof AuthAdminIndexRoute
  '/_auth/_app/subjects/$id': typeof AuthAppSubjectsIdRouteRouteWithChildren
  '/_auth/_app/accessions/new': typeof AuthAppAccessionsNewRoute
  '/_auth/_app/accessions/newv2': typeof AuthAppAccessionsNewv2Route
  '/_auth/_app/board/$id': typeof AuthAppBoardIdRoute
  '/_auth/_app/help/notifications': typeof AuthAppHelpNotificationsRoute
  '/_auth/_app/auth/profile': typeof AuthAppAuthProfileLazyRoute
  '/_auth/admin/clients/new': typeof AuthAdminClientsNewLazyRoute
  '/_auth/admin/instruments/new': typeof AuthAdminInstrumentsNewLazyRoute
  '/_auth/admin/labs/new': typeof AuthAdminLabsNewLazyRoute
  '/_auth/admin/panels/new': typeof AuthAdminPanelsNewLazyRoute
  '/_auth/admin/pods/new': typeof AuthAdminPodsNewLazyRoute
  '/_auth/admin/results/new': typeof AuthAdminResultsNewLazyRoute
  '/_auth/admin/tests/new': typeof AuthAdminTestsNewLazyRoute
  '/_auth/admin/tubes/new': typeof AuthAdminTubesNewLazyRoute
  '/_auth/admin/workflows/new': typeof AuthAdminWorkflowsNewLazyRoute
  '/_auth/_app/accessions/': typeof AuthAppAccessionsIndexLazyRoute
  '/_auth/_app/help/': typeof AuthAppHelpIndexLazyRoute
  '/_auth/_app/reports/': typeof AuthAppReportsIndexLazyRoute
  '/_auth/_app/runs/': typeof AuthAppRunsIndexLazyRoute
  '/_auth/_app/search/': typeof AuthAppSearchIndexLazyRoute
  '/_auth/admin/audit/': typeof AuthAdminAuditIndexLazyRoute
  '/_auth/admin/auth/': typeof AuthAdminAuthIndexLazyRoute
  '/_auth/admin/clients/': typeof AuthAdminClientsIndexLazyRoute
  '/_auth/admin/instruments/': typeof AuthAdminInstrumentsIndexLazyRoute
  '/_auth/admin/labs/': typeof AuthAdminLabsIndexLazyRoute
  '/_auth/admin/panels/': typeof AuthAdminPanelsIndexLazyRoute
  '/_auth/admin/pods/': typeof AuthAdminPodsIndexLazyRoute
  '/_auth/admin/results/': typeof AuthAdminResultsIndexLazyRoute
  '/_auth/admin/settings/': typeof AuthAdminSettingsIndexLazyRoute
  '/_auth/admin/tests/': typeof AuthAdminTestsIndexLazyRoute
  '/_auth/admin/tubes/': typeof AuthAdminTubesIndexLazyRoute
  '/_auth/admin/users/': typeof AuthAdminUsersIndexLazyRoute
  '/_auth/admin/workflows/': typeof AuthAdminWorkflowsIndexLazyRoute
  '/_auth/_app/accessions/$id/modal': typeof AuthAppAccessionsIdModalRoute
  '/_auth/_app/accessions_/$id/report': typeof AuthAppAccessionsIdReportRoute
  '/_auth/_app/subjects/$id/accession': typeof AuthAppSubjectsIdAccessionRoute
  '/_auth/_app/subjects/$id/create': typeof AuthAppSubjectsIdCreateRoute
  '/_auth/_app/subjects/$id/report': typeof AuthAppSubjectsIdReportRoute
  '/_auth/admin/clients/$id/edit': typeof AuthAdminClientsIdEditRoute
  '/_auth/admin/instruments/$id/edit': typeof AuthAdminInstrumentsIdEditRoute
  '/_auth/admin/labs/$id/edit': typeof AuthAdminLabsIdEditRoute
  '/_auth/admin/panels/$id/edit': typeof AuthAdminPanelsIdEditRoute
  '/_auth/admin/pods/$id/edit': typeof AuthAdminPodsIdEditRoute
  '/_auth/admin/results/$id/edit': typeof AuthAdminResultsIdEditRoute
  '/_auth/admin/tubes/$id/edit': typeof AuthAdminTubesIdEditRoute
  '/_auth/admin/users/$id/edit': typeof AuthAdminUsersIdEditRoute
  '/_auth/admin/workflows/$id/edit': typeof AuthAdminWorkflowsIdEditRoute
  '/_auth/admin/auth/providers/new': typeof AuthAdminAuthProvidersNewLazyRoute
  '/_auth/admin/tubes/field-sets/new': typeof AuthAdminTubesFieldSetsNewLazyRoute
  '/_auth/admin/tubes/fields/new': typeof AuthAdminTubesFieldsNewLazyRoute
  '/_auth/_app/accessions_/$id/': typeof AuthAppAccessionsIdIndexRoute
  '/_auth/_app/runs/$id/': typeof AuthAppRunsIdIndexRoute
  '/_auth/_app/runs/$id/worklist/$testId': typeof AuthAppRunsIdWorklistTestIdRouteWithChildren
  '/_auth/admin/auth/providers/$id/edit': typeof AuthAdminAuthProvidersIdEditRoute
  '/_auth/admin/tubes/field-sets/$id/edit': typeof AuthAdminTubesFieldSetsIdEditRoute
  '/_auth/admin/tubes/fields/$id/edit': typeof AuthAdminTubesFieldsIdEditRoute
  '/_auth/admin/tests/$id/edit/': typeof AuthAdminTestsIdEditIndexRoute
  '/_auth/admin/tests/$id/edit/results/$specId': typeof AuthAdminTestsIdEditResultsSpecIdRoute
  '/_auth/_app/runs/$id/worklist/$testId/results/$orderId': typeof AuthAppRunsIdWorklistTestIdResultsOrderIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/error'
    | '/health'
    | '/login'
    | '/admin'
    | '/accessions'
    | '/reports'
    | '/runs'
    | '/board'
    | '/'
    | '/admin/'
    | '/subjects/$id'
    | '/accessions/new'
    | '/accessions/newv2'
    | '/board/$id'
    | '/help/notifications'
    | '/auth/profile'
    | '/admin/clients/new'
    | '/admin/instruments/new'
    | '/admin/labs/new'
    | '/admin/panels/new'
    | '/admin/pods/new'
    | '/admin/results/new'
    | '/admin/tests/new'
    | '/admin/tubes/new'
    | '/admin/workflows/new'
    | '/accessions/'
    | '/help'
    | '/reports/'
    | '/runs/'
    | '/search'
    | '/admin/audit'
    | '/admin/auth'
    | '/admin/clients'
    | '/admin/instruments'
    | '/admin/labs'
    | '/admin/panels'
    | '/admin/pods'
    | '/admin/results'
    | '/admin/settings'
    | '/admin/tests'
    | '/admin/tubes'
    | '/admin/users'
    | '/admin/workflows'
    | '/accessions/$id/modal'
    | '/accessions/$id/report'
    | '/subjects/$id/accession'
    | '/subjects/$id/create'
    | '/subjects/$id/report'
    | '/admin/clients/$id/edit'
    | '/admin/instruments/$id/edit'
    | '/admin/labs/$id/edit'
    | '/admin/panels/$id/edit'
    | '/admin/pods/$id/edit'
    | '/admin/results/$id/edit'
    | '/admin/tubes/$id/edit'
    | '/admin/users/$id/edit'
    | '/admin/workflows/$id/edit'
    | '/admin/auth/providers/new'
    | '/admin/tubes/field-sets/new'
    | '/admin/tubes/fields/new'
    | '/accessions/$id'
    | '/runs/$id'
    | '/runs/$id/worklist/$testId'
    | '/admin/auth/providers/$id/edit'
    | '/admin/tubes/field-sets/$id/edit'
    | '/admin/tubes/fields/$id/edit'
    | '/admin/tests/$id/edit'
    | '/admin/tests/$id/edit/results/$specId'
    | '/runs/$id/worklist/$testId/results/$orderId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/error'
    | '/health'
    | '/login'
    | '/board'
    | '/'
    | '/admin'
    | '/subjects/$id'
    | '/accessions/new'
    | '/accessions/newv2'
    | '/board/$id'
    | '/help/notifications'
    | '/auth/profile'
    | '/admin/clients/new'
    | '/admin/instruments/new'
    | '/admin/labs/new'
    | '/admin/panels/new'
    | '/admin/pods/new'
    | '/admin/results/new'
    | '/admin/tests/new'
    | '/admin/tubes/new'
    | '/admin/workflows/new'
    | '/accessions'
    | '/help'
    | '/reports'
    | '/runs'
    | '/search'
    | '/admin/audit'
    | '/admin/auth'
    | '/admin/clients'
    | '/admin/instruments'
    | '/admin/labs'
    | '/admin/panels'
    | '/admin/pods'
    | '/admin/results'
    | '/admin/settings'
    | '/admin/tests'
    | '/admin/tubes'
    | '/admin/users'
    | '/admin/workflows'
    | '/accessions/$id/modal'
    | '/accessions/$id/report'
    | '/subjects/$id/accession'
    | '/subjects/$id/create'
    | '/subjects/$id/report'
    | '/admin/clients/$id/edit'
    | '/admin/instruments/$id/edit'
    | '/admin/labs/$id/edit'
    | '/admin/panels/$id/edit'
    | '/admin/pods/$id/edit'
    | '/admin/results/$id/edit'
    | '/admin/tubes/$id/edit'
    | '/admin/users/$id/edit'
    | '/admin/workflows/$id/edit'
    | '/admin/auth/providers/new'
    | '/admin/tubes/field-sets/new'
    | '/admin/tubes/fields/new'
    | '/accessions/$id'
    | '/runs/$id'
    | '/runs/$id/worklist/$testId'
    | '/admin/auth/providers/$id/edit'
    | '/admin/tubes/field-sets/$id/edit'
    | '/admin/tubes/fields/$id/edit'
    | '/admin/tests/$id/edit'
    | '/admin/tests/$id/edit/results/$specId'
    | '/runs/$id/worklist/$testId/results/$orderId'
  id:
    | '__root__'
    | '/_auth'
    | '/error'
    | '/health'
    | '/login'
    | '/_auth/admin'
    | '/_auth/_app'
    | '/_auth/_app/accessions'
    | '/_auth/_app/reports'
    | '/_auth/_app/runs'
    | '/_auth/_app/board'
    | '/_auth/_app/'
    | '/_auth/admin/'
    | '/_auth/_app/subjects/$id'
    | '/_auth/_app/accessions/new'
    | '/_auth/_app/accessions/newv2'
    | '/_auth/_app/board/$id'
    | '/_auth/_app/help/notifications'
    | '/_auth/_app/auth/profile'
    | '/_auth/admin/clients/new'
    | '/_auth/admin/instruments/new'
    | '/_auth/admin/labs/new'
    | '/_auth/admin/panels/new'
    | '/_auth/admin/pods/new'
    | '/_auth/admin/results/new'
    | '/_auth/admin/tests/new'
    | '/_auth/admin/tubes/new'
    | '/_auth/admin/workflows/new'
    | '/_auth/_app/accessions/'
    | '/_auth/_app/help/'
    | '/_auth/_app/reports/'
    | '/_auth/_app/runs/'
    | '/_auth/_app/search/'
    | '/_auth/admin/audit/'
    | '/_auth/admin/auth/'
    | '/_auth/admin/clients/'
    | '/_auth/admin/instruments/'
    | '/_auth/admin/labs/'
    | '/_auth/admin/panels/'
    | '/_auth/admin/pods/'
    | '/_auth/admin/results/'
    | '/_auth/admin/settings/'
    | '/_auth/admin/tests/'
    | '/_auth/admin/tubes/'
    | '/_auth/admin/users/'
    | '/_auth/admin/workflows/'
    | '/_auth/_app/accessions/$id/modal'
    | '/_auth/_app/accessions_/$id/report'
    | '/_auth/_app/subjects/$id/accession'
    | '/_auth/_app/subjects/$id/create'
    | '/_auth/_app/subjects/$id/report'
    | '/_auth/admin/clients/$id/edit'
    | '/_auth/admin/instruments/$id/edit'
    | '/_auth/admin/labs/$id/edit'
    | '/_auth/admin/panels/$id/edit'
    | '/_auth/admin/pods/$id/edit'
    | '/_auth/admin/results/$id/edit'
    | '/_auth/admin/tubes/$id/edit'
    | '/_auth/admin/users/$id/edit'
    | '/_auth/admin/workflows/$id/edit'
    | '/_auth/admin/auth/providers/new'
    | '/_auth/admin/tubes/field-sets/new'
    | '/_auth/admin/tubes/fields/new'
    | '/_auth/_app/accessions_/$id/'
    | '/_auth/_app/runs/$id/'
    | '/_auth/_app/runs/$id/worklist/$testId'
    | '/_auth/admin/auth/providers/$id/edit'
    | '/_auth/admin/tubes/field-sets/$id/edit'
    | '/_auth/admin/tubes/fields/$id/edit'
    | '/_auth/admin/tests/$id/edit/'
    | '/_auth/admin/tests/$id/edit/results/$specId'
    | '/_auth/_app/runs/$id/worklist/$testId/results/$orderId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  ErrorRoute: typeof ErrorRoute
  HealthRoute: typeof HealthRoute
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  ErrorRoute: ErrorRoute,
  HealthRoute: HealthRoute,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/error",
        "/health",
        "/login"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/admin",
        "/_auth/_app"
      ]
    },
    "/error": {
      "filePath": "error.tsx"
    },
    "/health": {
      "filePath": "health.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_auth/admin": {
      "filePath": "_auth.admin/route.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/admin/",
        "/_auth/admin/clients/new",
        "/_auth/admin/instruments/new",
        "/_auth/admin/labs/new",
        "/_auth/admin/panels/new",
        "/_auth/admin/pods/new",
        "/_auth/admin/results/new",
        "/_auth/admin/tests/new",
        "/_auth/admin/tubes/new",
        "/_auth/admin/workflows/new",
        "/_auth/admin/audit/",
        "/_auth/admin/auth/",
        "/_auth/admin/clients/",
        "/_auth/admin/instruments/",
        "/_auth/admin/labs/",
        "/_auth/admin/panels/",
        "/_auth/admin/pods/",
        "/_auth/admin/results/",
        "/_auth/admin/settings/",
        "/_auth/admin/tests/",
        "/_auth/admin/tubes/",
        "/_auth/admin/users/",
        "/_auth/admin/workflows/",
        "/_auth/admin/clients/$id/edit",
        "/_auth/admin/instruments/$id/edit",
        "/_auth/admin/labs/$id/edit",
        "/_auth/admin/panels/$id/edit",
        "/_auth/admin/pods/$id/edit",
        "/_auth/admin/results/$id/edit",
        "/_auth/admin/tubes/$id/edit",
        "/_auth/admin/users/$id/edit",
        "/_auth/admin/workflows/$id/edit",
        "/_auth/admin/auth/providers/new",
        "/_auth/admin/tubes/field-sets/new",
        "/_auth/admin/tubes/fields/new",
        "/_auth/admin/auth/providers/$id/edit",
        "/_auth/admin/tubes/field-sets/$id/edit",
        "/_auth/admin/tubes/fields/$id/edit",
        "/_auth/admin/tests/$id/edit/",
        "/_auth/admin/tests/$id/edit/results/$specId"
      ]
    },
    "/_auth/_app": {
      "filePath": "_auth._app/route.lazy.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_app/accessions",
        "/_auth/_app/reports",
        "/_auth/_app/runs",
        "/_auth/_app/board",
        "/_auth/_app/",
        "/_auth/_app/subjects/$id",
        "/_auth/_app/help/notifications",
        "/_auth/_app/auth/profile",
        "/_auth/_app/help/",
        "/_auth/_app/search/",
        "/_auth/_app/accessions_/$id/report",
        "/_auth/_app/accessions_/$id/"
      ]
    },
    "/_auth/_app/accessions": {
      "filePath": "_auth._app/accessions/route.tsx",
      "parent": "/_auth/_app",
      "children": [
        "/_auth/_app/accessions/new",
        "/_auth/_app/accessions/newv2",
        "/_auth/_app/accessions/",
        "/_auth/_app/accessions/$id/modal"
      ]
    },
    "/_auth/_app/reports": {
      "filePath": "_auth._app/reports/route.tsx",
      "parent": "/_auth/_app",
      "children": [
        "/_auth/_app/reports/"
      ]
    },
    "/_auth/_app/runs": {
      "filePath": "_auth._app/runs/route.tsx",
      "parent": "/_auth/_app",
      "children": [
        "/_auth/_app/runs/",
        "/_auth/_app/runs/$id/",
        "/_auth/_app/runs/$id/worklist/$testId"
      ]
    },
    "/_auth/_app/board": {
      "filePath": "_auth._app/board/route.lazy.tsx",
      "parent": "/_auth/_app",
      "children": [
        "/_auth/_app/board/$id"
      ]
    },
    "/_auth/_app/": {
      "filePath": "_auth._app/index.tsx",
      "parent": "/_auth/_app"
    },
    "/_auth/admin/": {
      "filePath": "_auth.admin/index.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/_app/subjects/$id": {
      "filePath": "_auth._app/subjects.$id/route.tsx",
      "parent": "/_auth/_app",
      "children": [
        "/_auth/_app/subjects/$id/accession",
        "/_auth/_app/subjects/$id/create",
        "/_auth/_app/subjects/$id/report"
      ]
    },
    "/_auth/_app/accessions/new": {
      "filePath": "_auth._app/accessions/new.tsx",
      "parent": "/_auth/_app/accessions"
    },
    "/_auth/_app/accessions/newv2": {
      "filePath": "_auth._app/accessions/newv2.tsx",
      "parent": "/_auth/_app/accessions"
    },
    "/_auth/_app/board/$id": {
      "filePath": "_auth._app/board/$id.tsx",
      "parent": "/_auth/_app/board"
    },
    "/_auth/_app/help/notifications": {
      "filePath": "_auth._app/help/notifications.tsx",
      "parent": "/_auth/_app"
    },
    "/_auth/_app/auth/profile": {
      "filePath": "_auth._app/auth/profile.lazy.tsx",
      "parent": "/_auth/_app"
    },
    "/_auth/admin/clients/new": {
      "filePath": "_auth.admin/clients/new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/instruments/new": {
      "filePath": "_auth.admin/instruments/new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/labs/new": {
      "filePath": "_auth.admin/labs/new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/panels/new": {
      "filePath": "_auth.admin/panels/new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/pods/new": {
      "filePath": "_auth.admin/pods/new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/results/new": {
      "filePath": "_auth.admin/results/new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/tests/new": {
      "filePath": "_auth.admin/tests/new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/tubes/new": {
      "filePath": "_auth.admin/tubes/new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/workflows/new": {
      "filePath": "_auth.admin/workflows/new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/_app/accessions/": {
      "filePath": "_auth._app/accessions/index.lazy.tsx",
      "parent": "/_auth/_app/accessions"
    },
    "/_auth/_app/help/": {
      "filePath": "_auth._app/help/index.lazy.tsx",
      "parent": "/_auth/_app"
    },
    "/_auth/_app/reports/": {
      "filePath": "_auth._app/reports/index.lazy.tsx",
      "parent": "/_auth/_app/reports"
    },
    "/_auth/_app/runs/": {
      "filePath": "_auth._app/runs/index.lazy.tsx",
      "parent": "/_auth/_app/runs"
    },
    "/_auth/_app/search/": {
      "filePath": "_auth._app/search/index.lazy.tsx",
      "parent": "/_auth/_app"
    },
    "/_auth/admin/audit/": {
      "filePath": "_auth.admin/audit/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/auth/": {
      "filePath": "_auth.admin/auth/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/clients/": {
      "filePath": "_auth.admin/clients/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/instruments/": {
      "filePath": "_auth.admin/instruments/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/labs/": {
      "filePath": "_auth.admin/labs/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/panels/": {
      "filePath": "_auth.admin/panels/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/pods/": {
      "filePath": "_auth.admin/pods/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/results/": {
      "filePath": "_auth.admin/results/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/settings/": {
      "filePath": "_auth.admin/settings/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/tests/": {
      "filePath": "_auth.admin/tests/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/tubes/": {
      "filePath": "_auth.admin/tubes/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/users/": {
      "filePath": "_auth.admin/users/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/workflows/": {
      "filePath": "_auth.admin/workflows/index.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/_app/accessions/$id/modal": {
      "filePath": "_auth._app/accessions/$id.modal.tsx",
      "parent": "/_auth/_app/accessions"
    },
    "/_auth/_app/accessions_/$id/report": {
      "filePath": "_auth._app/accessions_.$id/report.tsx",
      "parent": "/_auth/_app"
    },
    "/_auth/_app/subjects/$id/accession": {
      "filePath": "_auth._app/subjects.$id/accession.tsx",
      "parent": "/_auth/_app/subjects/$id"
    },
    "/_auth/_app/subjects/$id/create": {
      "filePath": "_auth._app/subjects.$id/create.tsx",
      "parent": "/_auth/_app/subjects/$id"
    },
    "/_auth/_app/subjects/$id/report": {
      "filePath": "_auth._app/subjects.$id/report.tsx",
      "parent": "/_auth/_app/subjects/$id"
    },
    "/_auth/admin/clients/$id/edit": {
      "filePath": "_auth.admin/clients/$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/instruments/$id/edit": {
      "filePath": "_auth.admin/instruments/$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/labs/$id/edit": {
      "filePath": "_auth.admin/labs/$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/panels/$id/edit": {
      "filePath": "_auth.admin/panels/$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/pods/$id/edit": {
      "filePath": "_auth.admin/pods/$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/results/$id/edit": {
      "filePath": "_auth.admin/results/$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/tubes/$id/edit": {
      "filePath": "_auth.admin/tubes/$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/users/$id/edit": {
      "filePath": "_auth.admin/users/$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/workflows/$id/edit": {
      "filePath": "_auth.admin/workflows/$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/auth/providers/new": {
      "filePath": "_auth.admin/auth/providers.new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/tubes/field-sets/new": {
      "filePath": "_auth.admin/tubes/field-sets.new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/tubes/fields/new": {
      "filePath": "_auth.admin/tubes/fields.new.lazy.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/_app/accessions_/$id/": {
      "filePath": "_auth._app/accessions_.$id/index.tsx",
      "parent": "/_auth/_app"
    },
    "/_auth/_app/runs/$id/": {
      "filePath": "_auth._app/runs/$id.index.tsx",
      "parent": "/_auth/_app/runs"
    },
    "/_auth/_app/runs/$id/worklist/$testId": {
      "filePath": "_auth._app/runs/$id.worklist.$testId.tsx",
      "parent": "/_auth/_app/runs",
      "children": [
        "/_auth/_app/runs/$id/worklist/$testId/results/$orderId"
      ]
    },
    "/_auth/admin/auth/providers/$id/edit": {
      "filePath": "_auth.admin/auth/providers.$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/tubes/field-sets/$id/edit": {
      "filePath": "_auth.admin/tubes/field-sets.$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/tubes/fields/$id/edit": {
      "filePath": "_auth.admin/tubes/fields.$id.edit.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/tests/$id/edit/": {
      "filePath": "_auth.admin/tests/$id.edit.index.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/admin/tests/$id/edit/results/$specId": {
      "filePath": "_auth.admin/tests/$id.edit.results.$specId.tsx",
      "parent": "/_auth/admin"
    },
    "/_auth/_app/runs/$id/worklist/$testId/results/$orderId": {
      "filePath": "_auth._app/runs/$id.worklist.$testId.results.$orderId.tsx",
      "parent": "/_auth/_app/runs/$id/worklist/$testId"
    }
  }
}
ROUTE_MANIFEST_END */
