import { CheckIcon, CopyIcon } from "lucide-react";
import { useState } from "react";
import { type VariantProps, tv } from "tailwind-variants";

import { Button } from "./button";
import { cn } from "./primitive";
import { toast } from "./toast";

const styles = tv({
  slots: {
    list: "grid grid-cols-[auto_1fr] gap-y-3 text-foreground", // Removed divide-y, added gap-y-3
    item: "grid grid-cols-subgrid col-[span_2] items-baseline",
    label: "text-xs text-muted-foreground",
    value: "text-[13px] text-pretty",
  },
});

const dataListStyles = tv({
  base: [
    "gap-y-3 text-foreground",
    "[&_dd]:text-xs [&_dd]:text-muted-foreground",
    "[&_dt]:text-pretty",
  ],
  variants: {
    orientation: {
      horizontal: [
        "grid grid-cols-[auto_1fr]",
        "[&>div]:grid [&>div]:grid-cols-subgrid [&>div]:col-[span_2] [&>div]:items-baseline",
        "[&_dd]:min-w-[var(--data-list-width)] [&_dt]:text-[13px]",
      ],
      vertical: [
        "flex flex-col w-full",
        "[&>div]:flex [&>div]:flex-col [&>div]:gap-y-1",
        "[&_dd]:flex [&_dd]:min-w-0",
        "[&_dt]:flex [&_dt]:min-w-0 [&_dt]:text-sm [&_dt]:w-full",
      ],
    },
  },
  defaultVariants: {
    orientation: "horizontal",
  },
});

function DataList({
  children,
  labelWidth = 110,
  className,
  orientation,
}: {
  children: React.ReactNode;
  labelWidth?: number;
  className?: string;
} & VariantProps<typeof dataListStyles>) {
  return (
    <dl
      className={dataListStyles({ orientation, className })}
      style={{ "--data-list-width": `${labelWidth}px` } as React.CSSProperties}
    >
      {children}
    </dl>
  );
}

function DataListItem({
  children,
  className,
}: { children: React.ReactNode; className?: string }) {
  return <div className={className}>{children}</div>;
}

function DataListValue({
  children,
  className,
  copyable = false,
}: { children?: React.ReactNode; className?: string; copyable?: boolean }) {
  const child = typeof children === "string" ? children.trim() : children;
  const { copyableProps, copyButton } = useCopy(child);

  return (
    <dt className={cn(!children && "text-muted-foreground/50", className)}>
      <div
        className={cn("flex items-center relative w-full", copyable && "pr-4")}
        {...copyableProps}
      >
        {child || <span aria-hidden="true">&mdash;</span>}
        {copyable && copyButton}
      </div>
    </dt>
  );
}

function DataListLabel({ children }: { children: React.ReactNode }) {
  return <dd>{children}</dd>;
}

DataList.Item = DataListItem;
DataList.Value = DataListValue;
DataList.Label = DataListLabel;

export { DataList };

function useCopy(child: React.ReactNode) {
  const [showCopy, setShowCopy] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    let text = child;
    if (child && typeof child === "object" && "props" in child) {
      // biome-ignore lint/suspicious/noExplicitAny: shrug
      text = (child.props as any).children;
    }
    if (typeof text === "string") {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
            setShowCopy(false);
          }, 2000);
        })
        .catch(() => {
          toast.error("Failed to copy");
        });
    } else {
      toast.error("Failed to copy");
    }
  };

  const copyActionProps = {
    onPress: handleCopy,
    excludeFromTabOrder: true,
    children: copied ? (
      <CheckIcon data-slot="icon" />
    ) : (
      <CopyIcon data-slot="icon" />
    ),
  };

  return {
    showCopy,
    copied,
    copyActionProps,
    copyableProps: {
      onPointerEnter: () => setShowCopy(true),
      onPointerLeave: () => setShowCopy(() => copied),
    },
    copyButton: (
      <>
        {child && showCopy ? (
          <Button
            appearance="plain"
            size="square-small"
            isDisabled={copied}
            className="absolute right-0 bg-background"
            {...copyActionProps}
          />
        ) : null}
      </>
    ),
  };
}
