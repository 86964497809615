import type { TextProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";
import { Heading, type HeadingProps } from "./heading";
import { Loader } from "./loader";
import { Text } from "./text";

function EmptyState({
  className,
  isLoading,
  ...props
}: React.JSX.IntrinsicElements["div"] & { isLoading?: boolean }) {
  return isLoading ? (
    <Loader />
  ) : (
    <div
      {...props}
      className={twMerge(
        "flex h-full w-full flex-1 flex-col items-center justify-center gap-1 p-4 text-center @container",
        className,
      )}
    />
  );
}

function EmptyStateIcon({
  className,
  children,
  ...props
}: React.JSX.IntrinsicElements["div"]) {
  return (
    <div
      {...props}
      className={twMerge(
        "flex max-w-32 items-center justify-center @md:max-w-40",
        "[&>svg:not([class*=text-])]:text-muted-foreground [&>svg]:h-auto [&>svg]:min-w-6 [&>svg]:max-w-full",
        className,
      )}
    >
      {children}
    </div>
  );
}

function EmptyStateHeading({ className, level = 3, ...props }: HeadingProps) {
  return (
    <Heading
      {...props}
      level={level}
      className={twMerge("text-balance", className)}
    />
  );
}

function EmptyStateDescription({ className, ...props }: TextProps) {
  return (
    <Text
      {...props}
      muted
      className={twMerge("max-w-prose text-balance", className)}
    />
  );
}

function EmptyStateActions({
  className,
  ...props
}: React.JSX.IntrinsicElements["div"]) {
  return (
    <div
      {...props}
      className={twMerge(
        "mt-3 flex flex-col items-center justify-center gap-4 p-2",
        className,
      )}
    />
  );
}

EmptyState.Icon = EmptyStateIcon;
EmptyState.Heading = EmptyStateHeading;
EmptyState.Description = EmptyStateDescription;
EmptyState.Actions = EmptyStateActions;

export { EmptyState };
